import React from 'react'

import ImageRender from '../Common/Image'

const Image = (path) =>
  function (rowData) {
    return rowData[path] ? (
      <ImageRender
        data={Array.isArray(rowData[path]) ? rowData[path][0] : rowData[path]}
      />
    ) : (
      ''
    )
  }
export default Image
