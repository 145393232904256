import Button from '@material-ui/core/Button'
import React, { useState } from 'react'

import Guard from '../Guards/Guard'
import Dialog from './Dialog'

const ActionButton = function (props) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Button
        variant="contained"
        color={props.buttonColor}
        onClick={() => {
          if (props.withAction) props.onSubmit()
          else setOpen(true)
        }}
      >
        {props.buttonLabel}
      </Button>
      <Guard condition={open && !props.withAction}>
        <Dialog
          open={open}
          close={() => {
            setOpen(false)
            if (props.withClear) props.clearFunction()
          }}
          inputs={props.form?.inputs || []}
          title={props.form?.title || ''}
          onSubmit={(args) => {
            setOpen(false)
            props.onSubmit(args)
            if (props.withClear) props.clearFunction()
          }}
          body={props.body}
          setBody={props.setBody}
          withReset={props.withReset}
          resetFunction={props.resetFunction}
          info={props.info}
          confirm={props.confirm}
        />
      </Guard>
    </>
  )
}
export default ActionButton
