import { useSnackbar } from 'notistack'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import translate from '../locales/en'

const createMutation = (
  createApi,
  singleCollectionName,
  queryKey,
  invalidations
) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation((body) => createApi(body), {
    onError: (err) =>
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate['could not be']} ${translate.added}: ${err.response.data.message}`,
        { variant: 'error', TransitionProps: { direction: 'left' } }
      ),
    onSuccess: (data) => {
      queryClient.invalidateQueries(queryKey)
      if (invalidations)
        invalidations.forEach((invalidation) =>
          queryClient.invalidateQueries(invalidation)
        )
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate.added} ${
          translate.successfully
        } ${typeof data !== 'object' && data}`,
        { variant: 'success', TransitionProps: { direction: 'left' } }
      )
    },
  })
}
const getQuery = (queryKey, options, getApi) =>
  useQuery([queryKey, options], () => getApi(options))
const getOneQuery = (queryKey, id, getApi) =>
  useQuery([queryKey, { id }], () => getApi(id))
const updateMutation = (
  updateApi,
  id,
  singleCollectionName,
  singleQueryKey,
  queryKey
) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation((data) => updateApi(id, data), {
    onError: (err) => {
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate['could not be']} ${translate.edited}: ${err.response.data.message}`,
        { variant: 'error', TransitionProps: { direction: 'left' } }
      )
    },
    onSuccess: (data) => {
      if (data && typeof data !== 'object' && id !== data)
        navigate(`/${queryKey}/${data}`, { replace: true })
      queryClient.invalidateQueries([singleQueryKey, { id }])
      queryClient.invalidateQueries(queryKey)
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate.edited} ${translate.successfully}`,
        { variant: 'success', TransitionProps: { direction: 'left' } }
      )
    },
  })
}
const deleteMutation = (
  deleteApi,
  id,
  singleCollectionName,
  singleQueryKey,
  queryKey
) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation(() => deleteApi(id), {
    onError: (err) => {
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate['could not be']} ${translate.deleted}: ${err.response.data.message}`,
        { variant: 'error', TransitionProps: { direction: 'left' } }
      )
    },
    onSuccess: () => {
      queryClient.removeQueries([singleQueryKey, { id }])
      queryClient.invalidateQueries(queryKey)
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate.deleted} ${translate.successfully}`,
        { variant: 'success', TransitionProps: { direction: 'left' } }
      )
      navigate(-1, { replace: true })
    },
  })
}
const subCreateMutation = (
  createApi,
  id,
  singleCollectionName,
  singleQueryKey,
  data
) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation((Data) => createApi(id, { ...Data, ...data }), {
    onError: (err) =>
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate['could not be']} ${translate.added}: ${err.response.data.message}`,
        { variant: 'error', TransitionProps: { direction: 'left' } }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([singleQueryKey, { id }])
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate.added} ${translate.successfully}`,
        { variant: 'success', TransitionProps: { direction: 'left' } }
      )
    },
  })
}
const subGetOneMutation = (singleQueryKey, id, subId, getApi) =>
  useQuery([singleQueryKey, { id, subId }], () => getApi(id, subId))
const subUpdateMutation = (
  updateApi,
  id,
  subId,
  singleCollectionName,
  singleQueryKey
) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation((data) => updateApi(id, subId, data), {
    onError: (err) =>
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate['could not be']} ${translate.edited}: ${err.response.data.message}`,
        { variant: 'error', TransitionProps: { direction: 'left' } }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([singleQueryKey, { id, subId }])
      queryClient.invalidateQueries([singleQueryKey, { id }])
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate.edited} ${translate.successfully}`,
        { variant: 'success', TransitionProps: { direction: 'left' } }
      )
    },
  })
}
const subDeleteMutation = (
  deleteApi,
  id,
  subId,
  singleCollectionName,
  singleQueryKey
) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation((SubId) => deleteApi(id, subId || SubId), {
    onError: (err) =>
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate['could not be']} ${translate.deleted}: ${err.response.data.message}`,
        { variant: 'error', TransitionProps: { direction: 'left' } }
      ),
    onSuccess: () => {
      queryClient.removeQueries([singleQueryKey, { id, subId }])
      queryClient.invalidateQueries([singleQueryKey, { id }])
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate.deleted} ${translate.successfully}`,
        { variant: 'success', TransitionProps: { direction: 'left' } }
      )
      navigate(-1, { replace: true })
    },
  })
}
const subSubCreateMutation = (
  createApi,
  id,
  subId,
  singleCollectionName,
  singleQueryKey
) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation((data) => createApi(id, subId, data), {
    onError: (err) =>
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate['could not be']} ${translate.added}: ${err.response.data.message}`,
        { variant: 'error', TransitionProps: { direction: 'left' } }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([singleQueryKey, { id, subId }])
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate.added} ${translate.successfully}`,
        { variant: 'success', TransitionProps: { direction: 'left' } }
      )
    },
  })
}
const subSubGetOneMutation = (singleQueryKey, id, subId, subSubId, getApi) =>
  useQuery([singleQueryKey, { id, subId, subSubId }], () =>
    getApi(id, subId, subSubId)
  )
const subSubUpdateMutation = (
  updateApi,
  id,
  subId,
  subSubId,
  singleCollectionName,
  singleQueryKey
) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation((data) => updateApi(id, subId, subSubId, data), {
    onError: (err) =>
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate['could not be']} ${translate.edited}: ${err.response.data.message}`,
        { variant: 'error', TransitionProps: { direction: 'left' } }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([singleQueryKey, { id, subId, subSubId }])
      queryClient.invalidateQueries([singleQueryKey, { id, subId }])
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate.edited} ${translate.successfully}`,
        { variant: 'success', TransitionProps: { direction: 'left' } }
      )
    },
  })
}
const subSubDeleteMutation = (
  deleteApi,
  id,
  subId,
  subSubId,
  singleCollectionName,
  singleQueryKey
) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation(() => deleteApi(id, subId, subSubId), {
    onError: (err) =>
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate['could not be']} ${translate.deleted}: ${err.response.data.message}`,
        { variant: 'error', TransitionProps: { direction: 'left' } }
      ),
    onSuccess: () => {
      queryClient.removeQueries([singleQueryKey, { id, subId, subSubId }])
      queryClient.invalidateQueries([singleQueryKey, { id, subId }])
      enqueueSnackbar(
        `${translate[singleCollectionName]} ${translate.deleted} ${translate.successfully}`,
        { variant: 'success', TransitionProps: { direction: 'left' } }
      )
      navigate(-1, { replace: true })
    },
  })
}
export default {
  createMutation,
  getQuery,
  getOneQuery,
  updateMutation,
  deleteMutation,
  subCreateMutation,
  subGetOneMutation,
  subUpdateMutation,
  subDeleteMutation,
  subSubCreateMutation,
  subSubGetOneMutation,
  subSubUpdateMutation,
  subSubDeleteMutation,
}
