import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { categoryService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'
import Categories from '../Collections/Categories'

const Category = function () {
  const collectionName = 'Categorys'
  const singleCollectionName = 'Category'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    categoryService.get
  )
  const updateMutation = reactQuery.updateMutation(
    categoryService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    categoryService.remove,
    getQuery.data?.name,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  if (getQuery.isLoading)
    return <span className="spanText">{translate['Loading...']}</span>
  return (
    <>
      <Document
        actions={[
          {
            buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
            buttonColor: 'primary',
            onSubmit: (args) => {
              const newBody = objectUtils.compare(getQuery.data, args)
              const { media } = newBody
              delete newBody.media
              const formData = objectUtils.getFormData(newBody)
              if (media && media.length)
                Object.keys(media).forEach((key) =>
                  formData.append('media', media[key])
                )
              if (Object.keys(newBody).length || (media && media.length))
                return updateMutation.mutate(formData)
            },
            body,
            setBody,
            withClear: true,
            clearFunction: () => setBody({}),
            form: {
              title: `${translate.Update} ${translate[singleCollectionName]}`,
              inputs: [
                {
                  name: 'description',
                  label: 'Description',
                  type: 'text',
                  value: getQuery.data.description,
                },
                {
                  name: 'media',
                  label: 'Media',
                  type: 'file',
                  accept: 'image/*',
                },
              ],
            },
          },
          {
            buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
            buttonColor: 'secondary',
            onSubmit: () => deleteMutation.mutate(),
            form: {
              title: `${translate.Delete} ${translate[singleCollectionName]}`,
            },
            confirm: true,
            info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
          },
        ]}
        title={singleCollectionName}
        getQuery={getQuery}
        many={false}
        notToShow={['parent', 'category', 'isLeaf']}
      />
      <Categories parent={getQuery.data.category} />
    </>
  )
}
export default Category
