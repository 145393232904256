import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { orderService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Order = function () {
  const collectionName = 'Orders'
  const singleCollectionName = 'Order'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    orderService.get
  )
  const updateMutation = reactQuery.updateMutation(
    orderService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    orderService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(getQuery.data, args)
            if (Object.keys(newBody).length)
              return updateMutation.mutate(newBody)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'status',
                label: 'Status',
                type: 'select',
                options: types.orderTypes.map((orderType) => ({
                  label: stringUtils.camelCase(orderType),
                  value: orderType,
                })),
                value: getQuery.data?.status,
                required: true,
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Order
