module.exports = {
  Amount: 'Amount',
  Add: 'Add',
  'Are you sure you want to create this':
    'Are you sure you want to create this',
  'Are you sure you want to delete this':
    'Are you sure you want to delete this',
  'Are you sure you want to manage this':
    'Are you sure you want to manage this',
  'Audio File': 'Audio File',
  Brand: 'Brand',
  'Rosavicci Admin Panel': 'Rosavicci Admin Panel',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  'Contact Us': 'Contact Us',
  ContactUs: 'ContactUs',
  Contacted: 'Contacted',
  'Created At': 'Created At',
  Currency: 'Currency',
  Date: 'Date',
  'Date Published': 'Date Published',
  Day: 'Day',
  Deadline: 'Deadline',
  Delete: 'Delete',
  Description: 'Description',
  Email: 'Email',
  'End Hour': 'End Hour',
  'End Minute': 'End Minute',
  'End Time': 'End Time',
  Error: 'Error',
  Filter: 'Filter',
  Filters: 'Filters',
  Friday: 'Friday',
  Generate: 'Generate',
  Home: 'Home',
  'Loading...': 'Loading...',
  'Logged in': 'Logged in',
  Login: 'Login',
  Logs: 'Logs',
  Manage: 'Manage',
  Media: 'Media',
  Message: 'Message',
  'Missing Information': 'Missing Information',
  Monday: 'Monday',
  Month: 'Month',
  Name: 'Name',
  News: 'News',
  No: 'No',
  Option: 'Option',
  Options: 'Options',
  Other: 'Other',
  Password: 'Password',
  'Phone Number': 'Phone Number',
  Priority: 'Priority',
  'Profile Picture': 'Profile Picture',
  Reset: 'Reset',
  Saturday: 'Saturday',
  Server: 'Server',
  'Start Hour': 'Start Hour',
  'Start Minute': 'Start Minute',
  'Start Time': 'Start Time',
  Status: 'Status',
  Submit: 'Submit',
  Sunday: 'Sunday',
  'Thumbnail Picture': 'Thumbnail Picture',
  Thursday: 'Thursday',
  Tuesday: 'Tuesday',
  Type: 'Type',
  Update: 'Update',
  'Updated At': 'Updated At',
  'Updated By Name': 'Updated By Name',
  'Updated Keys': 'Updated Keys',
  User: 'User',
  'User Name': 'User Name',
  Users: 'Users',
  Verified: 'Verified',
  Video: 'Video',
  'Video File': 'Video File',
  'Video Type': 'Video Type',
  Videos: 'Videos',
  Wednesday: 'Wednesday',
  Year: 'Year',
  Yes: 'Yes',
  added: 'added',
  'can not be empty': 'can not be empty',
  'could not be': 'could not be',
  deleted: 'deleted',
  edited: 'edited',
  generated: 'generated',
  'is invalid': 'is invalid',
  'is required': 'is required',
  'password must be of minimum length 8 and contain at least 1 letter and 1 number':
    'password must be of minimum length 8 and contain at least 1 letter and 1 number',
  successfully: 'successfully',
  'Export CSV': 'Export CSV',
  'Export PDF': 'Export PDF',
  City: 'City',
  Address: 'Address',
  'Address Info': 'Address Info',
  'Date Time': 'Date Time',
  Code: 'Code',
  Blacklisted: 'Blacklisted',
  'Validity Days': 'Validity Days',
  'Expiry Date': 'Expiry Date',
  Parent: 'Parent',
  Category: 'Category',
  'Is Leaf': 'Is Leaf',
  Expires: 'Expires',
  'Contact Email': 'Contact Email',
  Specifications: 'Specifications',
  Price: 'Price',
  Featured: 'Featured',
  'On Sale': 'On Sale',
  'Sale Price': 'Sale Price',
  'Out Of Stock': 'Out Of Stock',
  Color: 'Color',
  Size: 'Size',
  Variant: 'Variant',
  Variants: 'Variants',
  'Wish List': 'Wish List',
  Item: 'Item',
  'Item Name': 'Item Name',
  Items: 'Items',
  Subtotal: 'Subtotal',
  Shipping: 'Shipping',
  'Gift Card': 'Gift Card',
  Total: 'Total',
  Token: 'Token',
  Role: 'Role',
  'Verified Phone Number': 'Verified Phone Number',
  Cart: 'Cart',
  Appointment: 'Appointment',
  Appointments: 'Appointments',
  Card: 'Card',
  Cards: 'Cards',
  Categories: 'Categories',
  Codes: 'Codes',
  Gift: 'Gift',
  'Gift Cards': 'Gift Cards',
  Gifts: 'Gifts',
  Orders: 'Orders',
  Order: 'Order',
  'Price Greater Than': 'Price Greater Than',
  'Price Less Than': 'Price Less Than',
  'Number Of Pieces': 'Number Of Pieces',
  Dimensions: 'Dimensions',
  'Textile Material': 'Textile Material',
  Includes: 'Includes',
  'Indoor Or Outdoor': 'Indoor Or Outdoor',
  Features: 'Features',
  'Hanging Style': 'Hanging Style',
  'Care And Cleaning': 'Care And Cleaning',
  'Item Number': 'Item Number',
  Origin: 'Origin',
}
