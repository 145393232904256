import MaterialTable from '@material-table/core'
import { ExportCsv, ExportPdf } from '@material-table/exporters'
import CircleIcon from '@mui/icons-material/Circle'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import dateUtils from '../../_helpers/dateUtils'
import stringUtils from '../../_helpers/stringUtils'
import translate from '../../locales/en'
import Guard from '../Guards/Guard'
import Button from './Button'
import ImageRender from './Common/Image'
import Boolean from './TableColumns/Boolean'
import Category from './TableColumns/Category'
import Currency from './TableColumns/Currency'
import DateTime from './TableColumns/DateTime'
import Image from './TableColumns/Image'
import Message from './TableColumns/Message'

const Document = function (props) {
  const checkIsToBeShow = (key, value) => {
    if (
      key.toLowerCase().endsWith('id') ||
      key.toLowerCase().endsWith('slug') ||
      key.toLowerCase().endsWith('code') ||
      key === 'count' ||
      key === 'pages' ||
      key === 'page' ||
      key === 'tableData' ||
      props.notToShow?.includes(key) ||
      value === null ||
      value === undefined ||
      value === ''
    )
      return false
    return true
  }
  const getType = (data) =>
    typeof data === 'object'
      ? Array.isArray(data)
        ? 'array'
        : 'object'
      : 'nonObject'
  const switchCasesOnArrayKeysRender = (key) => {
    switch (key) {
      case 'price':
      case 'salePrice':
      case 'subtotal':
      case 'giftCard':
      case 'total':
      case 'amount':
        return Currency(key)
      case 'expires':
      case 'expiryDate':
      case 'dateTime':
      case 'createdAt':
      case 'updatedAt':
        return DateTime(key)
      case 'contacted':
      case 'verified':
      case 'verifiedPhoneNumber':
      case 'blacklisted':
      case 'featured':
      case 'onSale':
      case 'outOfStock':
      case 'variant':
      case 'isLeaf':
        return Boolean(key)
      case 'message':
      case 'description':
        return Message(key)
      case 'media':
        return Image(key)
      case 'category':
        return Category(key)
      default:
        return null
    }
  }
  const switchCasesOnObjectKeysRender = (key, value) => {
    switch (key) {
      case 'price':
      case 'salePrice':
      case 'subtotal':
      case 'giftCard':
      case 'total':
      case 'amount':
        return stringUtils.currency(value)
      case 'expires':
      case 'expiryDate':
      case 'dateTime':
      case 'createdAt':
      case 'updatedAt':
        return dateUtils.formatDateTime(value)
      case 'contacted':
      case 'verified':
      case 'verifiedPhoneNumber':
      case 'blacklisted':
      case 'featured':
      case 'onSale':
      case 'outOfStock':
      case 'variant':
      case 'isLeaf':
        return stringUtils.boolean(value)
      case 'media':
        return <ImageRender data={value} />
      case 'category':
        return value.split('/').at(-1)
      default:
        return /\r|\n/.exec(value)
          ? value.split('\n').map((v) => <p>{v}</p>)
          : value
    }
  }
  const switchCasesOnActions = (name) => {
    switch (name) {
      case 'Appointments':
      case 'ContactUs':
        return [
          (rowData) => ({
            icon: () => (
              <CircleIcon
                style={{ color: rowData.contacted ? 'black' : 'red' }}
              />
            ),
          }),
        ]
      default:
        return null
    }
  }
  const switchCasesOnNavigation = (name, rowData) => {
    switch (name) {
      case 'variants':
      case 'items':
        return `/items/${rowData.slug}`
      default:
        return props.navigate
          ? props.navigate.reduce((str, el) => {
              str += `/${rowData[el] || el}`
              return str
            }, '')
          : rowData.code
          ? encodeURIComponent(rowData.code)
          : rowData.email || rowData.slug || rowData._id || null
    }
  }
  const setArrayId = (value) => {
    if (value) {
      value.forEach((v) => {
        if (v._id) v.id = v._id
      })
      return value
    }
    return []
  }
  const getArrayColumns = (value) => {
    let newColumns = []
    if (!value.length) return newColumns
    Object.keys(value[0]).forEach((el) => {
      if (Array.isArray(value[0][el]) || !checkIsToBeShow(el, value[0][el]))
        return
      const column = {
        title: stringUtils.camelCase(el),
        field: el,
      }
      const render = switchCasesOnArrayKeysRender(el)
      // const cellStyle = switchCasesOnArrayKeysStyle(el)
      if (render) column.render = render
      // if (cellStyle) column.cellStyle = cellStyle
      if (el.toLowerCase().endsWith('name'))
        newColumns = [column, ...newColumns]
      else newColumns.push(column)
    })
    return newColumns
  }
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (props.many) {
      if (searchParams.toString() === '')
        props.setQueryParams(props.defaultQueryParams)
      if (searchParams.get('query') === props.title) {
        const searchParamsObject = Object.fromEntries([...searchParams])
        delete searchParamsObject.query
        props.setQueryParams((s) => ({ ...s, ...searchParamsObject }))
      }
    }
  }, [searchParams])
  const [columns, setColumns] = useState([])
  useEffect(() => {
    if (props.many && !props.getQuery.isLoading && !columns.length)
      setColumns(props.getQuery.data.documents)
  }, [props.getQuery.isLoading, props.getQuery.data?.count])
  if (props.getQuery.isLoading && !props.many)
    return <span className="spanText">{translate['Loading...']}</span>
  if (props.getQuery.isError) {
    enqueueSnackbar(
      `${translate.Server} ${translate.Error}: ${props.getQuery.error.response.data.message}`,
      { variant: 'error', TransitionProps: { direction: 'left' } }
    )
    return (
      <span className="spanText">
        {`${translate.Error}: ${props.getQuery.error.response.data.message}`}
      </span>
    )
  }
  const nonObjects = []
  const objects = []
  const arrays = []
  if (props.many && props.getQuery.isLoading) arrays.push('documents')
  if (props.getQuery.data)
    Object.keys(props.getQuery.data).forEach((el) => {
      if (!checkIsToBeShow(el, props.getQuery.data[el])) return null
      const type = getType(props.getQuery.data[el])
      if (type === 'nonObject') nonObjects.push(el)
      if (type === 'object' && Object.keys(props.getQuery.data[el]).length)
        objects.push(el)
      if (type === 'array') arrays.push(el)
    })
  const getDataToShow = (key, value, type) => {
    if (!checkIsToBeShow(key, value)) return null
    switch (type) {
      case 'object':
        return (
          <>
            <div className="fullWidth center">
              <h2>
                <strong>{stringUtils.camelCase(key)}</strong>
              </h2>
            </div>
            <div className="fullWidth singleContainer">
              {Object.keys(value).map((el) => getDataToShow(el, value[el]))}
            </div>
          </>
        )
      case 'array':
        return typeof value[0] === 'object' ? (
          <>
            <div className="fullWidth center">
              <h2>
                <strong>
                  {stringUtils.camelCase(props.many ? props.title : key)}
                </strong>
              </h2>
            </div>
            <Guard condition={props.many && props.tableActions?.length}>
              <div className="collectionActions btnActions">
                {props.tableActions?.map((action) => (
                  <Button
                    key={action.buttonLabel}
                    buttonLabel={action.buttonLabel}
                    buttonColor={action.buttonColor}
                    form={action.form}
                    onSubmit={action.onSubmit}
                    body={action.body}
                    setBody={action.setBody}
                    confirm={action.confirm}
                    info={action.info}
                    withReset={action.withReset}
                    resetFunction={action.resetFunction}
                    withClear={action.withClear}
                    clearFunction={action.clearFunction}
                    withAction={action.withAction}
                  />
                ))}
              </div>
            </Guard>
            <div className="tableComponent">
              <MaterialTable
                title={stringUtils.camelCase(props.many ? props.title : key)}
                data={setArrayId(
                  props.many ? props.getQuery.data?.documents : value
                )}
                columns={getArrayColumns(props.many ? columns : value)}
                options={{
                  exportMenu: [
                    {
                      label: translate['Export PDF'],
                      exportFunc: (cols, data) =>
                        ExportPdf(
                          cols,
                          data,
                          stringUtils.camelCase(props.many ? props.title : key)
                        ),
                    },
                    {
                      label: translate['Export CSV'],
                      exportFunc: (cols, data) =>
                        ExportCsv(
                          cols,
                          data,
                          stringUtils.camelCase(props.many ? props.title : key)
                        ),
                    },
                  ],
                  pageSize: 10,
                  pageSizeOptions: [10, 50, 100, 200, 400],
                  searchDebounceDelay: 1000,
                  tableWidth: 'full',
                  padding: 'dense',
                  actionsColumnIndex: -1,
                  columnsButton: true,
                  maxBodyHeight: '50vh',
                  headerStyle: { textAlign: 'center' },
                }}
                actions={
                  switchCasesOnActions(props.many ? props.title : key) || []
                }
                onRowClick={(event, rowData) => {
                  const navigation = switchCasesOnNavigation(key, rowData)
                  if (navigation) navigate(navigation)
                }}
                style={{ overflowX: 'hidden' }}
                {...(props.many && {
                  isLoading: props.getQuery.isLoading,
                  page: (props.getQuery.data?.page || 1) - 1,
                  totalCount: props.getQuery.data?.count,
                  onPageChange: (page) =>
                    setSearchParams({
                      ...Object.fromEntries([...searchParams]),
                      page: page + 1,
                      query: props.title,
                    }),
                  onRowsPerPageChange: (limit) =>
                    setSearchParams({
                      ...Object.fromEntries([...searchParams]),
                      limit,
                      page: 1,
                      query: props.title,
                    }),
                  onSearchChange: (search) =>
                    setSearchParams({
                      ...Object.fromEntries([...searchParams]),
                      search,
                      page: 1,
                      query: props.title,
                    }),
                  onOrderChange: (index, order) =>
                    setSearchParams({
                      ...Object.fromEntries([...searchParams]),
                      sort: `${order === 'desc' ? '-' : ''}${
                        getArrayColumns(columns)[index]?.field || ''
                      }`,
                      page: 1,
                      query: props.title,
                    }),
                })}
              />
            </div>
          </>
        ) : typeof value[0] === 'string' || typeof value[0] === 'number' ? (
          <>
            <div className="fullWidth center">
              <h2>
                <strong>{stringUtils.camelCase(key)}</strong>
              </h2>
            </div>
            <div className="fullWidth singleContainer">
              {value.map((v) => (
                <div className="singleData">
                  {switchCasesOnObjectKeysRender(key, v)}
                </div>
              ))}
            </div>
          </>
        ) : null
      default:
        return (
          <div className="singleData">
            <strong>{stringUtils.camelCase(key)}</strong>
            {switchCasesOnObjectKeysRender(key, value)}
          </div>
        )
    }
  }
  return (
    <div className="singleComponent">
      <Guard condition={props.actions?.length}>
        <div className="btnActions">
          {props.actions?.map((action) => (
            <Button
              key={action.buttonLabel}
              buttonLabel={action.buttonLabel}
              buttonColor={action.buttonColor}
              form={action.form}
              onSubmit={action.onSubmit}
              body={action.body}
              setBody={action.setBody}
              confirm={action.confirm}
              info={action.info}
              withReset={action.withReset}
              resetFunction={action.resetFunction}
              withClear={action.withClear}
              clearFunction={action.clearFunction}
              withAction={action.withAction}
            />
          ))}
        </div>
      </Guard>
      <Guard condition={nonObjects.length}>
        <div className="fullWidth center">
          <h2>
            {translate[props.title]} {props.many && translate.Information}
          </h2>
        </div>
        <div className="fullWidth singleContainer">
          {nonObjects.map((el) =>
            getDataToShow(el, props.getQuery.data[el], 'nonObject')
          )}
        </div>
      </Guard>
      <Guard condition={objects.length}>
        {objects.map((el) => {
          if (
            Object.values(props.getQuery.data[el]).reduce(
              (flag, value) => !!value || flag,
              false
            )
          )
            return getDataToShow(el, props.getQuery.data[el], 'object')
          return null
        })}
      </Guard>
      <Guard condition={arrays.length}>
        {arrays.map((el) =>
          getDataToShow(
            el,
            props.getQuery.data?.[el].length
              ? props.getQuery.data[el]
              : props.many
              ? [{}]
              : [],
            'array'
          )
        )}
      </Guard>
    </div>
  )
}
export default Document
