import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { categoryService, itemService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Item = function () {
  const collectionName = 'Items'
  const singleCollectionName = 'Item'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    itemService.get
  )
  const updateMutation = reactQuery.updateMutation(
    itemService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    itemService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  const addVariantMutation = reactQuery.createMutation(
    itemService.create,
    singleCollectionName,
    singleQueryKey,
    [['item', { id: params.id }]]
  )
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            args.name = args.itemName
            delete args.itemName
            const newBody = objectUtils.compare(getQuery.data, args)
            const { media } = newBody
            delete newBody.media
            const formData = objectUtils.getFormData(newBody)
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('media', media[key])
              )
            if (Object.keys(newBody).length || (media && media.length))
              return updateMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'itemName',
                label: 'Item Name',
                type: 'text',
                value: getQuery.data?.name,
              },
              {
                name: 'category',
                label: 'Category',
                type: 'autocomplete',
                queryKey: 'categorys',
                getApi: categoryService.query,
                keys: { category: 'category', name: 'name' },
                value: {
                  category: getQuery.data?.category,
                  name: getQuery.data?.category.split('/').at(-1),
                },
              },
              ...(!getQuery.data?.variant
                ? [
                    {
                      name: 'description',
                      label: 'Description',
                      type: 'text',
                      value: getQuery.data?.description,
                    },
                  ]
                : []),
              {
                name: 'price',
                label: 'Price',
                type: 'number',
                value: getQuery.data?.price,
              },
              {
                name: 'salePrice',
                label: 'Sale Price',
                type: 'number',
                value: getQuery.data?.salePrice,
              },
              {
                name: 'color',
                label: 'Color',
                type: 'text',
                value: getQuery.data?.color,
              },
              {
                name: 'size',
                label: 'Size',
                type: 'text',
                value: getQuery.data?.size,
              },
              {
                name: 'featured',
                label: 'Featured',
                type: 'boolean',
                value: getQuery.data?.featured,
              },
              {
                name: 'onSale',
                label: 'On Sale',
                type: 'boolean',
                value: getQuery.data?.onSale,
              },
              {
                name: 'outOfStock',
                label: 'Out Of Stock',
                type: 'boolean',
                value: getQuery.data?.outOfStock,
              },
              {
                name: 'media',
                label: 'Media',
                type: 'file',
                accept: 'image/*',
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
        {
          buttonLabel: `${translate.Update} ${translate.Specifications}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(
              getQuery.data?.specifications || {},
              args
            )
            if (Object.keys(newBody).length)
              return updateMutation.mutate(
                objectUtils.getFormData({ specifications: newBody })
              )
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate.Specifications}`,
            inputs: [
              'numberOfPieces',
              'dimensions',
              'textileMaterial',
              'includes',
              'indoorOrOutdoor',
              'features',
              'hangingStyle',
              'careAndCleaning',
              'itemNumber',
              'origin',
            ].map((key) => ({
              name: key,
              label: stringUtils.camelCase(key),
              type: 'text',
              value: getQuery.data?.specifications?.[key],
            })),
          },
        },
        {
          buttonLabel: `${translate.Add} ${translate.Variant}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const { media } = args
            delete args.media
            const formData = objectUtils.getFormData({
              ...args,
              name: getQuery.data.name,
              category: getQuery.data.category,
              parentSlug: params.id,
              variant: true,
            })
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('media', media[key])
              )
            return addVariantMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate.Variant}`,
            inputs: [
              {
                name: 'price',
                label: 'Price',
                type: 'number',
                value: getQuery.data?.price,
                required: true,
              },
              {
                name: 'color',
                label: 'Color',
                type: 'text',
                value: getQuery.data?.color,
                required: true,
              },
              {
                name: 'size',
                label: 'Size',
                type: 'text',
                value: getQuery.data?.size,
                required: true,
              },
              {
                name: 'salePrice',
                label: 'Sale Price',
                type: 'number',
                value: getQuery.data?.salePrice,
              },
              {
                name: 'onSale',
                label: 'On Sale',
                type: 'boolean',
                value: getQuery.data?.onSale,
              },
              {
                name: 'outOfStock',
                label: 'Out Of Stock',
                type: 'boolean',
                value: getQuery.data?.outOfStock,
              },
              {
                name: 'media',
                label: 'Media',
                type: 'file',
                accept: 'image/*',
                required: true,
              },
            ],
          },
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
      notToShow={['variant', 'wishList']}
    />
  )
}
export default Item
