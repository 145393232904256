import React, { useEffect, useState } from 'react'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { categoryService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Category = function ({ parent = '/' }) {
  const collectionName = 'Categorys'
  const singleCollectionName = 'Category'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = { parent }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  useEffect(() => setQueryParams(defaultQueryParams), [parent])
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    categoryService.query
  )
  const createMutation = reactQuery.createMutation(
    categoryService.create,
    singleCollectionName,
    queryKey
  )
  return (
    <Document
      tableActions={[
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            if (parent === '/') args.category = `/${args.name}`
            else args.category = `${parent}/${args.name}`
            args.parent = parent
            const { media } = args
            delete args.media
            const formData = objectUtils.getFormData(args)
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('media', media[key])
              )
            return createMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
              ...(parent === '/'
                ? [
                    {
                      name: 'description',
                      label: 'Description',
                      type: 'text',
                      required: true,
                    },
                  ]
                : []),
              {
                name: 'media',
                label: 'Media',
                type: 'file',
                accept: 'image/*',
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      navigate={parent !== '/' ? ['categories', '_id'] : null}
      notToShow={['parent', 'category', 'isLeaf']}
    />
  )
}
export default Category
