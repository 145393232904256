import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { categoryService, itemService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Item = function () {
  const collectionName = 'Items'
  const singleCollectionName = 'Item'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(queryKey, queryParams, itemService.query)
  const createMutation = reactQuery.createMutation(
    itemService.create,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) => {
            if (args.categories)
              args.categories = args.categories
                .map((category) => category.name)
                .join(',')
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              ...args,
              query: collectionName,
            })
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'categories',
                label: 'Categories',
                type: 'autocompleteMultiple',
                queryKey: 'categorys',
                getApi: categoryService.query,
                keys: { name: 'name' },
                value: queryParams.categories?.length
                  ? queryParams.categories
                      .split(',')
                      .reduce((arr, key) => [...arr, { name: key }], [])
                  : '',
              },
              {
                name: 'priceGTE',
                label: 'Price Greater Than',
                type: 'number',
                value: queryParams.priceGTE,
              },
              {
                name: 'priceLTE',
                label: 'Price Less Than',
                type: 'number',
                value: queryParams.priceLTE,
              },
              {
                name: 'color',
                label: 'Color',
                type: 'text',
                value: queryParams.color,
              },
              {
                name: 'size',
                label: 'Size',
                type: 'text',
                value: queryParams.size,
              },
              {
                name: 'featured',
                label: 'Featured',
                type: 'boolean',
                value: queryParams.featured
                  ? JSON.parse(queryParams.featured)
                  : '',
              },
              {
                name: 'onSale',
                label: 'On Sale',
                type: 'boolean',
                value: queryParams.onSale ? JSON.parse(queryParams.onSale) : '',
              },
              {
                name: 'outOfStock',
                label: 'Out Of Stock',
                type: 'boolean',
                value: queryParams.outOfStock
                  ? JSON.parse(queryParams.outOfStock)
                  : '',
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const { media, itemName } = args
            delete args.media
            delete args.itemName
            args.name = itemName
            const formData = objectUtils.getFormData(args)
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('media', media[key])
              )
            return createMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'itemName',
                label: 'Item Name',
                type: 'text',
                required: true,
              },
              {
                name: 'category',
                label: 'Category',
                type: 'autocomplete',
                queryKey: 'categorys',
                getApi: categoryService.query,
                keys: { category: 'category', name: 'name' },
                required: true,
              },
              {
                name: 'description',
                label: 'Description',
                type: 'text',
                required: true,
              },
              {
                name: 'price',
                label: 'Price',
                type: 'number',
                required: true,
              },
              {
                name: 'salePrice',
                label: 'Sale Price',
                type: 'number',
              },
              {
                name: 'color',
                label: 'Color',
                type: 'text',
                required: true,
              },
              {
                name: 'size',
                label: 'Size',
                type: 'text',
                required: true,
              },
              {
                name: 'featured',
                label: 'Featured',
                type: 'boolean',
              },
              {
                name: 'onSale',
                label: 'On Sale',
                type: 'boolean',
              },
              {
                name: 'outOfStock',
                label: 'Out Of Stock',
                type: 'boolean',
              },
              {
                name: 'media',
                label: 'Media',
                type: 'file',
                accept: 'image/*',
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      notToShow={['variant', 'saved']}
    />
  )
}
export default Item
