import TextField from '@material-ui/core/TextField'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import dateUtils from '../../../_helpers/dateUtils'
import translate from '../../../locales/en'

const DateTime = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => !(props.required && !value.length),
  }))
  const [value, setValue] = useState(dateUtils.formatDatePicker(props.value))
  useEffect(() => {
    let isValid = true
    if (props.required && !value.length) isValid = false
    props.setIsValids((s) => {
      s[props.index] = isValid
      return s
    })
    if (isValid)
      props.setIsValid(
        props.isValids.reduce((isAllValid, key, i) => {
          if (i === props.index) return isAllValid && isValid
          return isAllValid && key
        }, true)
      )
    else props.setIsValid(false)
    props.setBody((s) => ({ ...s, [props.name]: value }))
  }, [value])
  return (
    <TextField
      margin="normal"
      label={translate[props.label]}
      type="datetime-local"
      fullWidth
      InputLabelProps={{ shrink: true }}
      key={`${props.index}TextField`}
      autoFocus={props.index === 0}
      value={value}
      onChange={(event) =>
        setValue(dateUtils.formatDatePicker(event.target.value))
      }
      required={props.required}
      error={props.required && !value.length}
      {...(props.required &&
        !value.length && {
          helperText: `${translate[props.label]} ${translate['is required']}`,
        })}
    />
  )
})
export default DateTime
