import TextField from '@material-ui/core/TextField'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import translate from '../../../locales/en'

const Password = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () =>
      !(props.required && /^(.{0,7}|[^0-9]*|[^a-z]*)$/.test(value)),
  }))
  const [value, setValue] = useState(props.value || '')
  useEffect(() => {
    let isValid = true
    if (props.required && /^(.{0,7}|[^0-9]*|[^a-z]*)$/.test(value))
      isValid = false
    props.setIsValids((s) => {
      s[props.index] = isValid
      return s
    })
    if (isValid)
      props.setIsValid(
        props.isValids.reduce((isAllValid, key, i) => {
          if (i === props.index) return isAllValid && isValid
          return isAllValid && key
        }, true)
      )
    else props.setIsValid(false)
    props.setBody((s) => ({ ...s, [props.name]: value }))
  }, [value])
  return (
    <TextField
      margin="normal"
      label={translate[props.label]}
      type="password"
      fullWidth
      InputLabelProps={{ shrink: true }}
      key={`${props.index}TextField`}
      autoFocus={props.index === 0}
      autoComplete="new-password"
      value={value}
      onChange={(event) => setValue(event.target.value)}
      required={props.required}
      error={props.required && /^(.{0,7}|[^0-9]*|[^a-z]*)$/.test(value)}
      {...(props.required &&
        /^(.{0,7}|[^0-9]*|[^a-z]*)$/.test(value) && {
          helperText: `${translate[props.label]} ${
            translate[
              'password must be of minimum length 8 and contain at least 1 letter and 1 number'
            ]
          }`,
        })}
    />
  )
})
export default Password
