import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import { authService } from '../../_services'
import Logo from '../../assets/images/logo.png'
import translate from '../../locales/en'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: theme.spacing(80),
      height: theme.spacing(80),
      position: 'fixed',
      top: '50%',
      left: '50%',
      /* bring your own prefixes */
      transform: 'translate(-50%, -50%)',
      borderRadius: '3%',
    },
    backgroundColor: '#18191b',
    height: '100vh',
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow:
      '0 0px 8px 0 rgba(0, 0, 0, 0.1), 0 0px 20px 0 rgba(0, 0, 0, 0.1)',
    width: '60%',
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  tpd: {
    position: 'absolute',
    fontSize: '26px',
  },
  button: {
    margin: theme.spacing(2),
    borderRadius: '10px',
    fontWeight: 'bold',
    width: '200px',
  },
}))
const Login = function () {
  const classes = useStyles()
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const login = () => {
    event.preventDefault()
    event.stopPropagation()
    authService
      .login({ email, password })
      .then(() =>
        enqueueSnackbar(`${translate['Logged in']} ${translate.successfully}`, {
          variant: 'success',
          TransitionProps: { direction: 'left' },
        })
      )
      .catch((err) =>
        enqueueSnackbar(
          `${translate.Login} ${translate.Error}: ${err.response.data.message}`,
          { variant: 'error', TransitionProps: { direction: 'left' } }
        )
      )
  }
  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src={Logo} className={clsx(classes.logo)} width="40%" alt="" />
          <Typography variant="h3" gutterBottom className={clsx(classes.tpd)}>
            {translate['Rosavicci Admin Panel']}
          </Typography>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
            onSubmit={login}
          >
            <TextField
              variant="outlined"
              className={clsx(classes.textField)}
              margin="normal"
              label={translate.Email}
              type="text"
              InputLabelProps={{ shrink: true }}
              key="0TextField"
              autoFocus
              autoComplete="new-password"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
              error={!/^\S+@\S+\.\S+$/.test(email)}
              {...(!/^\S+@\S+\.\S+$/.test(email) && {
                helperText: `${translate.Email} ${translate['is invalid']}`,
              })}
            />
            <TextField
              variant="outlined"
              className={clsx(classes.textField)}
              margin="normal"
              label={translate.Password}
              type="password"
              InputLabelProps={{ shrink: true }}
              key="1TextField"
              autoComplete="current-password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
              error={/^(.{0,7}|[^0-9]*|[^a-z]*)$/.test(password)}
              {...(/^(.{0,7}|[^0-9]*|[^a-z]*)$/.test(password) && {
                helperText: `${translate.Password} ${translate['is invalid']}`,
              })}
            />
            <Button
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              {translate.Login}
            </Button>
          </form>
        </Grid>
      </Paper>
    </div>
  )
}
export default Login
