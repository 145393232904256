import './index.css'

// import createCache from '@emotion/cache'
// import { CacheProvider } from '@emotion/react'
import {
  createTheme,
  // jssPreset,
  // StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles'
// import { create } from 'jss'
// import rtl from 'jss-rtl'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

// import rtlPlugin from 'stylis-plugin-rtl'
import localStorageUtils from './_helpers/localStorageUtils'
import Layout from './components/Layout/Layout'
import Login from './components/Login/Login'

const queryClient = new QueryClient()
const theme = createTheme({
  typography: {
    fontFamily: [
      'Segoe UI',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Symbol',
    ].join(','),
  },
  palette: {
    primary: { main: '#1a5b3d', contrastText: '#fff' },
    secondary: { main: '#B4181E', contrastText: '#fff' },
  },
  // direction: 'rtl',
})
// const cacheRtl = createCache({ key: 'muirtl', stylisPlugins: [rtlPlugin] })
// const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const App = function () {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorageUtils.exists(localStorageUtils.availableKeys.ACCESS_TOKEN)
  )
  useEffect(() => {
    const checkAccessToken = () =>
      setIsLoggedIn(
        localStorageUtils.exists(localStorageUtils.availableKeys.ACCESS_TOKEN)
      )
    window.addEventListener('storage', checkAccessToken)
    return () => window.removeEventListener('storage', checkAccessToken)
  }, [])
  return (
    <QueryClientProvider client={queryClient}>
      {/* <CacheProvider value={cacheRtl}> */}
      {/* <StylesProvider jss={jss}> */}
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {isLoggedIn ? <Layout /> : <Login />}
        </SnackbarProvider>
      </ThemeProvider>
      {/* </StylesProvider> */}
      {/* </CacheProvider> */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
export default App
