import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import Appointments from '../Collections/Appointments'
import Cards from '../Collections/Cards'
import Categories from '../Collections/Categories'
import ContactUss from '../Collections/ContactUss'
import Gifts from '../Collections/Gifts'
import Items from '../Collections/Items'
import Orders from '../Collections/Orders'
import Users from '../Collections/Users'
import Appointment from '../Documents/Appointment'
import Card from '../Documents/Card'
import Category from '../Documents/Category'
import ContactUs from '../Documents/ContactUs'
import Gift from '../Documents/Gift'
import Item from '../Documents/Item'
import Order from '../Documents/Order'
import Profile from '../Documents/Profile'
import User from '../Documents/User'
import RoleBasedGuard from '../Guards/RoleBasedGuard'

const MainRoutes = function () {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/orders" />} />
      <Route path="/appointments" element={<Appointments />} />
      <Route path="/appointments/:id" element={<Appointment />} />
      <Route path="/cards" element={<Cards />} />
      <Route path="/cards/:id" element={<Card />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/categories/:id" element={<Category />} />
      <Route path="/contactus" element={<ContactUss />} />
      <Route path="/contactus/:id" element={<ContactUs />} />
      <Route path="/gifts" element={<Gifts />} />
      <Route path="/gifts/:id" element={<Gift />} />
      <Route path="/items" element={<Items />} />
      <Route path="/items/:id" element={<Item />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/orders/:id" element={<Order />} />
      <Route path="/profile" element={<Profile />} />
      <Route
        path="/users"
        element={
          <RoleBasedGuard permitedRoles={['owner']}>
            <Users />
          </RoleBasedGuard>
        }
      />
      <Route
        path="/users/:id"
        element={
          <RoleBasedGuard permitedRoles={['owner']}>
            <User />
          </RoleBasedGuard>
        }
      />
      <Route path="*" element={<Orders />} />
    </Routes>
  )
}
export default MainRoutes
