import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { userService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Users = function () {
  const collectionName = 'Users'
  const singleCollectionName = 'User'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(queryKey, queryParams, userService.query)
  const createMutation = reactQuery.createMutation(
    userService.createUser,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              ...args,
              query: collectionName,
            }),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'verified',
                label: 'Verified',
                type: 'boolean',
                value: queryParams.verified
                  ? JSON.parse(queryParams.verified)
                  : '',
              },
              {
                name: 'role',
                label: 'Role',
                type: 'select',
                value: queryParams.role,
                options: ['', ...types.userTypes].map((userType) => ({
                  label: stringUtils.camelCase(userType),
                  value: userType,
                })),
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => createMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'email',
                label: 'Email',
                type: 'email',
                required: true,
              },
              {
                name: 'password',
                label: 'Password',
                type: 'password',
                required: true,
              },
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
              {
                name: 'role',
                label: 'Role',
                type: 'select',
                options: types.userTypes.map((userType) => ({
                  label: stringUtils.camelCase(userType),
                  value: userType,
                })),
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default Users
